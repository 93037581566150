import * as React from "react";
import Header from "../../Components/Header/Header";
import NotFound from "../../Components/NotFound/NotFound";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const NotFoundPageEn = () => {
  return (
    <div>
      <Header lang="en-US" />
      <HamburgerMenu />
      <NotFound />
      <Partnership lang="en-US" />
      <Footer lang="en-US" />
    </div>
  );
};

export default NotFoundPageEn;
